import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_a7pbxsv", "template_w6c7aul", form.current, {
        publicKey: "wA4w8G6BQ0XHG_COf",
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className='containerctr'>
    <div className="p-6 bg-gray-50 dark:bg-slate-800 columnular">
    <p><strong>Contact Us</strong></p><br/>
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" /><br/><br/>
      <label>Email</label>
      <input type="email" name="user_email" /><br/><br/>
      <label>Message</label>
      <textarea name="message" /><br/><br/>
      <input type="submit" value="Send" class="py-[6px] px-4 inline-block items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400 hover:bg-amber-500 border border-amber-400 hover:border-amber-500 text-white font-semibold" />
    </form>
    </div>
    </div>
  );
};
  export default ContactForm;