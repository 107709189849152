import React,{useEffect} from "react";

import Navbar from "../components/navbar";
import BannerImg from "../assets/images/classic01.png"
import BrandLogo from "../components/brandLogo";
import AboutThree from "../components/aboutThree";
import AiFeatures from "../components/aiFeatures";
import AboutOne from "../components/aboutOne";
import AboutTwo from "../components/aboutTwo";
import Pricing from "../components/pricing";
import Footer from "../components/footer";

export default function IndexTwo(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      }, []);
    const workData = [
        {
            icon:'mdi mdi-account-search-outline',
            title:'DISCOVER',
            desc:'Explore markets like never before – without the time and costs of collecting data. Access to over 200,000 variables and data points. Ability to create your own market regions and explore right down to ZIP Code level precision'
        },
        {
            icon:'mdi mdi-layers-outline',
            title:'DEVELOP',
            desc:'Use our thousands of data layers or add your own. We can integrate any data into the system – thus allowing you to focus on developing strategies and action plans based on data'
        },
        {
            icon:'mdi mdi-chart-areaspline',
            title:'DELIVER',
            desc:'Track market growth, public opinion, sales, or advertising over time, location or market – all in one application. Even better, our team is helping you every step of the way'
        },
    ]
    return(
        <>
        <Navbar/>
        <section className="relative table w-full lg:py-40 md:py-36 pt-36 pb-24 overflow-hidden bg-white dark:bg-slate-900">
            <div className="container relative z-1">
                <div className="relative grid lg:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                    <div className="lg:col-span-7">
                        <div className="lg:me-6 lg:text-start text-center">
                            <h1 className="font-bold lg:leading-normal leading-normal text-3xl lg:text-5xl mb-5">Fueling Success.<br/>Empowering Innovation.</h1>
                            <p className="text-lg max-w-xl lg:ms-0 mx-auto"><span class="underline"><strong>RegionPulse</strong></span> clients obtain a deeper understanding of not only their customers – but&nbsp;
                            <span class="underline"><strong>ALL</strong></span> customers. Be able to immediately explore any market and demographic with our smart insight system.</p>                     
                            
                        </div>
                    </div>

                    <div className="lg:col-span-5">
                        <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-32 after:w-[36rem] after:h-[36rem] after:border-2 after:border-dashed after:border-slate-700/10 dark:after:border-slate-200/10 after:rounded-full after:animate-[spin_120s_linear_infinite] after:-z-1 before:content-[''] before:absolute lg:before:-top-24 before:-top-36 before:-right-56 before:w-[48rem] before:h-[48rem] before:border-2 before:border-dashed before:border-slate-700/10 dark:before:border-slate-200/10 before:rounded-full before:animate-[spin_240s_linear_infinite] before:-z-1">
                            <div className="relative after:content-[''] after:absolute lg:after:-top-24 after:-top-10 after:-right-100 after:w-[42rem] after:h-[42rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
                                <img src={BannerImg} className="lg:max-w-none lg:ms-14" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="relative pt-6 md:pb-24 pb-16 overflow-hidden">
            <span className="absolute blur-[200px] w-[500px] h-[500px] rounded-full top-[25%] -start-[20%] bg-gradient-to-tl to-amber-400  from-fuchsia-600 -z-1"></span>
            <span className="absolute blur-[200px] w-[500px] h-[500px] rounded-full bottom-[25%] -end-[20%] bg-gradient-to-tl to-amber-400  from-fuchsia-600 -z-1"></span>

            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Why RegionPulse?</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">As companies and their agencies have strived to gain greater insights often trapped in
Walled Gardens – RegionPulse was built to boost knowledge, performance, and drive
revenues and customer acquisition.</p>
                </div>

                <div className="grid md:grid-cols-3 grid-cols-1 mt-6 gap-6">
                    {workData.map((item,index)=>{
                        return(
                            <div className="relative p-6" key={index}>
                                <i className={`${item.icon} bg-gradient-to-tl to-amber-400 from-fuchsia-600 text-transparent bg-clip-text text-[45px]`}></i>
        
                                <h5 className="text-xl font-semibold my-5">{item.title}</h5>
        
                                <p className="text-slate-400">{item.desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>

            <AboutThree/>
            <AiFeatures/>
            <AboutOne/>



        </section>
        <Footer/>
        </>
    )
}