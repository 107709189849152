import React,{useEffect} from "react";
import { Link } from "react-router-dom";

import ContactForm from '../components/ContactForm';

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import {FiHexagon, FiMail, FiMapPin, FiLinkedin} from "../assets/icons/vander"

export default function Contact(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      }, []);
    return(
        <>
        <Navbar/>

        <section className="relative lg:py-24 py-16">
            
            <div className="container lg:mt-24 mt-16">
            <ContactForm />
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                    <div className="text-center px-6">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto"/>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                               <FiMail/>
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="title h5 text-lg font-semibold">Email</h5>
                            
                            <div className="mt-5">
                                <Link to="mailto:info@regionpulse.com" className="hover:text-amber-400">info@regionpulse.com</Link>
                            </div>
                        </div>
                    </div>

                    <div className="text-center px-6">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto"/>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                            <FiLinkedin />
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="title h5 text-lg font-semibold">LinkedIn</h5>
                            
                            <div className="mt-5">
                                <Link to="https://www.linkedin.com/company/regionpulse" className="hover:text-amber-400">RegionPulse LinkedIn</Link>
                            </div>
                        </div>
                    </div>

                    <div className="text-center px-6">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto"/>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                                <FiMapPin/>
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="title h5 text-lg font-semibold">Locations</h5>
                            <p className="text-slate-400 mt-3">United States<br/>Singapore<br/>Sweden<br/>Canada</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}