import React,{useState} from "react";
import { Link } from "react-router-dom";

import germany from '../assets/images/flags/germany.png'
import india from '../assets/images/flags/india.png'
import italy from '../assets/images/flags/italy.png'
import russia from '../assets/images/flags/russia.png'
import spain from '../assets/images/flags/spain.png'
import usa from '../assets/images/flags/usa.png'
import videoImg from "../assets/images/modern.mp4"

import {FiCheckCircle} from '../assets/icons/vander'

import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/css/modal-video.css'

export default function AboutThree(){
    const [isOpen, setOpen] = useState(false);
    const countryData = [
        {
            name:"Italian",
            image:italy
        },
        {
            name:"Hindi",
            image:india
        },
        {
            name:"Russian",
            image:russia
        },
        {
            name:"Spanish",
            image:spain
        },
        {
            name:"English",
            image:usa
        },
    ]
    return(
        <>
        <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800">
                        <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                            <div className="relative">
                                <video controls autoPlay loop>
                                    <source src={videoImg+"#t=1,30"} type="video/mp4"/>
                                </video>
                            </div>
    
                        </div>
                        
                    </div>

                    <div className="">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"><span class="underline"><strong>RegionPulse</strong></span> is our framework for advanced, made-to-measure data visualisation and analysis. Essentially with&nbsp;
<span class="underline"><strong>RegionPulse</strong></span>, <strong>WE CAN GIVE YOUR DATA THE INTELLIGENCE IT DESERVES</strong>.</h3>

                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2 shrink-0"/> Access to millions of variables across 180 countries, including spending, demographic, psychographic – plus the ability to
integrate any of your own first party data</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2 shrink-0"/> Ability to create standardized reports for each and every market – even better, we create the reports for you</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2 shrink-0"/> Our team is available to help create any reports and upload any data into the sytem – allowing your team to focus on using
the data to deliver success</li>
                        </ul>

                        <div className="mt-4">
                            <Link to="contact" className="hover:text-amber-400 font-medium duration-500">Find Out More <i className="mdi mdi-chevron-right text-[20px] align-middle"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo
				channel="youtube"
				youtube={{ mute: 0, autoplay: 0 }}
				isOpen={isOpen}
				videoId="S_CGed6E610"
				onClose={() => setOpen(false)} 
			/>
        </>
    )
}