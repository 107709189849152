import React,{ useState} from "react";
import { Link } from "react-router-dom";

import {FiCheckCircle,AiOutlineClose} from '../assets/icons/vander'

export default function Pricing(){
    const [businessPrice, setBusinessPrice] = useState(20) 
    const [professionalPrice, setProfessionalPrice] = useState(40)

    let businessUpdate = (parseFloat(businessPrice * 0.05).toFixed(1))

    let professionalUpdate = (parseFloat(professionalPrice * 0.025).toFixed(1))
    return(
        <>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                    <div className="p-6">
                        <h5 className="text-2xl leading-normal font-semibold">Free</h5>
                        <p className="text-slate-400 mt-2">2 Month Trial *</p>
                        <div className="flex mt-4">
                            <span className="text-lg font-semibold">$</span>
                            <span className="text-5xl font-semibold mb-0 ms-1">0</span>
                        </div>
                        <p className="text-slate-400 uppercase text-xs">per month</p>

                        <div className="mt-2">
                        <Link to="/contact" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded">Subscribe Now</Link>
                        <p className="text-slate-400 mt-4">Try for free</p>
                        </div>
                    </div>

                    <div className="p-6 bg-gray-50 dark:bg-slate-800">
                        <ul className="list-none text-slate-400">
                            <li className="font-semibold text-slate-900 dark:text-white text-sm uppercase">What's Included:</li>
                            
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 3 market regions (up to State Level per Region)</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 5 variables to be integrated per report</span></li> 
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Access for up to 5 users</span></li> 
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">5 hours of insight support</span></li> 
                            <li className="flex items-center mt-2">* After free trial, we can create a custom price based on your requirements. Business size restrictions may apply</li>
                            <li className="flex items-center mt-8"></li> 
                            <li className="flex items-center mt-8"></li>
                            <li className="flex items-center mt-8"></li>
                            <li className="flex items-center mt-8"></li>
                            <li className="flex items-center mt-2"></li>                    
                        </ul>
                    </div>
                </div>

                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                    <div className="p-6">
                        <h5 className="text-2xl leading-normal font-semibold">Agency</h5>
                        <p className="text-slate-400 mt-2">For Agency, Media and Medium
sized businesses</p>
                        
                        <div className="relative">
                            <div className="flex mt-2">
                                <span className="text-lg font-semibold">$</span>
                                <span className="text-5xl font-semibold mb-0 ms-1">15,000</span>
                            </div>
                            <p className="text-slate-400 uppercase text-xs"></p>
                        </div>
                        <div className="mt-2">
                            <Link to="/contact" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded">Subscribe Now</Link>
                        </div>
                        <p className="text-slate-400 mt-4">10,000 Credits valid for
12 months</p>
                    </div>

                    <div className="p-6 bg-gray-50 dark:bg-slate-800">
                        <ul className="list-none text-slate-400">
                            <li className="font-semibold text-slate-900 dark:text-white text-sm uppercase">10,000 credits get:</li>
                            
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 3 market regions (up to State Level per Region)</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 5 different clients or major projects</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 10 variable layers per project</span></li> 
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Inclusion of up to 3 first party data variables per project**</span></li> 
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 20 users and 30 hours of insight support</span></li> 
                            <li className="flex items-center mt-2">** Data size limits may apply. We will inform prior to undertaking any project additional costs</li>
                            <li className="flex items-center mt-8"></li> 
                            <li className="flex items-center mt-8"></li>
                            <li className="flex items-center mt-8"></li>
                            <li className="flex items-center mt-2"></li>
                        </ul>
                    </div>
                </div>

                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                    <div className="p-6">
                        <h5 className="text-2xl leading-normal font-semibold">Professional</h5>
                        <p className="text-slate-400 mt-4">For large agencies or organizations</p>

                        <div className="relative">
                            <div className="flex mt-4">
                                <span className="text-lg font-semibold">$</span>
                                <span className="text-4xl font-semibold mb-0 ms-1">10,000 / </span>
                                <span className="text-lg font-semibold">$</span>
                                <span className="text-4xl font-semibold mb-0 ms-1">60,000</span>
                            </div>
                            <p className="text-slate-400 uppercase text-xs">per month / annually</p>

                        </div>
                        <div className="mt-2">
                            <Link to="/contact" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded">Subscribe Now</Link>
                        </div>
                        <p className="text-slate-400 mt-4">Unlimited Credits</p>
                    </div>

                    <div className="p-6 bg-gray-50 dark:bg-slate-800">
                        <ul className="list-none text-slate-400">
                            <li className="font-semibold text-slate-900 dark:text-white text-sm uppercase">You get:</li>
                            
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">National mapping included for each project</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 5 different clients or major projects</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 10 variable layers per project</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Inclusion of up to 10 first party data variables per project**</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">Up to 40 users across 10 different offices</span></li>
                            <li className="flex items-center mt-2"><FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2 shrink-0 shrink-0"/><span className="text-slate-900 dark:text-white me-1 font-semibold">20 hours of insight support per month</span></li>
                            <li className="flex items-center mt-8"></li>
                            <li className="flex items-center mt-8"></li> 
                            <li className="flex items-center mt-8"></li>
                            <li className="flex items-center mt-8"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}