import React from "react";
import {FiMapPin, FiDatabase, FiMove, FiLayout, FiTrendingUp, FiSettings} from "../assets/icons/vander"
export default function AiFeatures(){
    const featureData = [
        {
            icon:FiMapPin,
            title:'Mapping Visualizations',
            desc:'Location planning based on sales, competitors, retail location, demographics, media usage, and psychographic variables'
        },
        {
            icon:FiDatabase,
            title:'Data Integration',
            desc:'Ability to integrate any data into the system, including Tokenized PII or using our API to integrate and secure any data'
        },
        {
            icon:FiMove,
            title:'Flexibility',
            desc:'Flexible to choose from thousands of our variables – or provide us with your internal data to overlay and track performance and KPIs'
        },
        {
            icon:FiLayout,
            title:'Reporting',
            desc:'All data and reports can be exported and integrated into PowerBI reports or dashboards'
        },
        {
            icon:FiTrendingUp,
            title:'Track Performance',
            desc:'Performance benchmarking across multiple industries with granular data analytics. Break down by total sales across spending categories or across your own locations'
        },
        {
            icon:FiSettings,
            title:'100% Support',
            desc:'Our team develops all reports and dashboards and data integration – thus saving your team hundreds of hours'
        },
    ]
    return(
        <>
        <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">How detailed can we go?</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">From how much people spend on Nikes to which travel magazines they like the most, we can break it down and provide you answers right
down to the zip code level. Our <strong><span class="underline">RegionPulse</span></strong> platform delivers spend insights and data trends.</p>
                </div>

                <div className="grid md:grid-cols-3 grid-cols-1 mt-6 gap-6">
                    {featureData.map((item,index)=>{
                        const Icon = item.icon
                        return(
                            <div className="group flex duration-500 xl:p-3" key={index}>
                                <div className="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-amber-400/5 group-hover:bg-amber-400 group-hover:text-white border-2 border-amber-400/20 text-amber-400 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 duration-500">
                                    <Icon className="w-5 h-5"/>
                                </div>
                                <div className="flex-1 ms-4">
                                    <h4 className="mb-0 text-lg font-semibold">{item.title}</h4>
                                    <p className="text-slate-400 mt-2">{item.desc}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
